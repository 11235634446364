import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import base from './api/base'

Vue.config.productionTip = false

Vue.prototype.$api = api

const getHost = (url) =>{
  let arr = url.split(".")
  if(process.env.NODE_ENV == "development"){
    return "theiabay.store"
  }else{
    if(arr.length == 3){
      return arr[1]+'.'+arr[2]
    }else if(arr.length == 2){
      return arr[0]+'.'+arr[1]
    }else{
      return "tbay.store"
    }
  }
}

if(process.env.NODE_ENV == "development"){
  base.url = '/api'
}
if(process.env.NODE_ENV == "test"){
  base.url = 'http://oss.tpay.com/api'
}
if(process.env.NODE_ENV == "production"){
  base.url = process.env.VUE_APP_API + getHost(window.location.host) + '/api'
}

//移动端
if(navigator.userAgent.match(/iPad|iPod|iPhone|Mobile|UP.Browser|Android|BlackBerry|Windows CE|Windows Phone|Nokia|webOS|SonyEricsson|Opera Mini|opera mobi|IEMobile|POLARIS/) != null){
  if(window.location.href.indexOf('www') == -1){
    let href = `//h5.${window.location.host}`
    window.location.href = href
  }else{
    let href = `//${window.location.host.replace('www.', 'www.h5.')}`
    window.location.href = href
  }
}

window.imgUrl = '//img.'+ getHost(window.location.host) +'/img/'

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
