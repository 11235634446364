import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'

Vue.use(Vuex)

const state = {
  //loading
  LOADING: false,  
}

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state,
  getters, //获取状态入口
  mutations, //修改状态入口（同步）
  actions, //修改设置状态（异步，建议使用异步）
})
