/*home模块接口列表*/
const home = [
  {
    url: 'getBlogNav', //博客分类
    code: 628245,
  },{
    url: 'getBlogBanner', //博客banner
    code: 630506,
  },{
    url: 'getBlogList', //分类查询博客列表
    code: 628252,
  },{
    url: 'getBlogAd', //播客广告列表
    code: 630510,
  },{
    url: 'getFootNav', //播客底部导航
    code: 628257,
  }
]

export default home